<template>
	<transition name="pane-ancien-mutations">
		<div class="paneleft pan-ancien-mutations">
			<div class="module_ret">
			<!--
				<div class="btns export" title="Export Excel">
					<download-excel :data="getJsonAncien()" :name="getNameFile()"  worksheet="efocus_dvf">
						<div><icon file="download" :size="24" /></div>
					</download-excel>
				</div>
			-->
			<!---->
				<div class="btns export" title="Export Excel">
					<vue-json-to-xls  :data="getJsonAncien()">
						<button class="btns export">
							<div><icon file="download" :size="24" /></div>
						</button>
					</vue-json-to-xls>
				</div>
			<!---->
				<div class="btns filtreancien" @click="$store.dispatch('set_view', { view: 'ancien' })"><icon file="back" :size="24" /></div>
				<div class="head_filter" @click.stop="cv = !cv" title="Filtrer ma recherche">
					<icon file="filter_list" :size="36" class="filters" />&nbsp;
				</div>
			</div>
			<div class="unmodule_detail_titre" style="height:84px !important;position: absolute;top:60px;border-right:1px solid #ccc">
				<!--<div class="icone_module"><img src="../../assets/img/cityscan37.png" alt="cityscan" style="height:30px;width:30px;text-align:left"></div>-->
				<div class="icone_module"></div>
				<div class="thematique">{{ $t('module') }}</div>
				<div class="titre_sommaire"><b>{{ $t('liste_des_transactions') }}</b></div>					
				<div style="position:absolute;color:grey;bottom:2px;right:5px;font-size:12px">{{ biens_comparables.length }} dernière<span v-if="biens_comparables.length>1">s</span> {{$t('transaction')}}<span v-if="biens_comparables.length>1">s</span></div>	

			</div>
			<div class="_filtre">
				<div class="box">
					<transition name="fade">
						<div v-if="cv" style="border-bottom: 4px solid #41b983; padding-bottom: 10px; padding-top: 10px; padding-top: 10px; height: 530px">
							<table class="_box-title2">
								<th style="display:none"></th>
								<tr>
									<td colspan="4">
										<p class="">{{ $t('Prix') }}</p>
									</td>
								</tr>
								<tr>
									<td style="width:50%">
										<div class="p-inputgroup">
											<InputNumber inputStyle="width:100%;text-align:right" id="integeronly" v-model="price_min" @change="valideInterval('price_min', $event)" @focus="selectContent"/>
											<span class="p-inputgroup-addon">€, min</span>
										</div>
									</td>
									<td style="width:10px">&nbsp;</td>
									<td style="width:50%">
										<div class="p-inputgroup">
										<InputNumber inputStyle="width:100%;text-align:right" id="integeronly" v-model="price_max" @change="valideInterval('price_max', $event)" @focus="selectContent"/>
										<span class="p-inputgroup-addon">€, max</span>
										</div>
									</td>
									<td style="width:10px">&nbsp;</td>
								</tr>
								<tr>
									<td colspan="4">
										<p style="padding-top:8px">{{ $t('Surface') }}</p>
									</td>
								</tr>
								<tr>
									<td>
										<div class="p-inputgroup">
										<InputNumber inputStyle="width:100%;text-align:right" id="integeronly" v-model="area_min" @change="valideInterval('area_min', $event)" @focus="selectContent"/>
										<span class="p-inputgroup-addon">m², min</span>
										</div>
									</td>
									<td style="width:10px">&nbsp;</td>
									<td>
										<div class="p-inputgroup">
										<InputNumber inputStyle="width:90px;text-align:right" id="integeronly" v-model="area_max" @change="valideInterval('area_max', $event)" @focus="selectContent"/>
										<span class="p-inputgroup-addon">m², max</span>
										</div>
									</td>
									<td style="width:10px">&nbsp;</td>
								</tr>
							</table>
							<p class="box-title2">{{ $t('forme_urbaine') }}</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="col = !col; toggle_switch('forme', 'collectif')" class="filtre" :class="{ active: col }">{{ $t('collectif') }}</div>
									<div @click="ind = !ind; toggle_switch('forme', 'individuel')" class="filtre" :class="{ active: ind }">{{ $t('individuel') }}</div>									
								</div>
							</div>
							<br/>
							<div class="">								
								<p class="_box-title2" style="margin: 0px !important; padding-top: 0px !important;">{{ $t('typologie') }}</p>
								<div class="box-content">
									<div class="switches">
										<switch-button size2="80px" :label="$t('t1')" icon="1" divise="five" @click.native="t1 = !t1; toggle_switch('t1')" :class="{ active: t1 }" />
										<switch-button size2="80px" :label="$t('t2')" icon="2" divise="five" @click.native="t2 = !t2; toggle_switch('t2')" :class="{ active: t2 }" />
										<switch-button size2="80px" :label="$t('t3')" icon="3" divise="five" @click.native="t3 = !t3; toggle_switch('t3')" :class="{ active: t3 }" />
										<switch-button size2="80px" :label="$t('t4')" icon="4" divise="five" @click.native="t4 = !t4; toggle_switch('t4')" :class="{ active: t4 }" />
										<switch-button size2="80px" :label="$t('t5')" icon="5" divise="five" @click.native="t5 = !t5; toggle_switch('t5')" :class="{ active: t5 }" />
									</div>
								</div>
							</div>	
							<p class="box-title2">{{ $t('transactions_realisees') }}</p>
							<div class="box-content">
								<p class="box-title" style="text-align:center" v-if="this.nbmois>1">{{ $t('durant_les') }} {{this.nbmois}} {{ $t('derniers_mois') }}</p>
								<p class="box-title" style="text-align:center" v-else>{{ $t('depuis_le_mois_dernier') }}</p>
								<div style="padding-top:5px !important;"><Slider v-model="nbmois" :step="1" :max="60" /></div>
							</div>
							<br>
							<div style="display: flex;justify-content: center;">
								<div class="app_filtre" style="width:200px" @click="applique_filtre()">{{ $t('appliquer_les_filtres') }}</div>
							</div>
						</div>
					</transition>
				</div>
				<div class="programs-list" >
					<div class="programs-list-content wrapper-scroll" :class="{ff:cv}">
						<div class="infinite-loader" v-if="app.loading_mutations" style="text-align: center;">
							<div class="text">
								<p>{{$t('Recherche des transactions')}}</p>
								<p>{{ $t('en_cours') }} ...</p>
								<p>&nbsp;</p>
							</div>
							<div class="loader"><icon file="loader" :size="64" style="color:#333" /></div>
						</div>
						<div class="content" ref="scrollable_parcelles" v-else>
							<pane-ancien-mutation
							v-for="mutation in biens_comparables"
							:mutation="mutation"
							class="bien hov"
							:id="'parcelle-' + mutation.id"
							v-bind:key="mutation.id"
							@mouseenter.native="hover_parcelle(mutation)"
							@mouseleave.native="unhover_parcelle(mutation)"
							/>
						</div>
						<!--
						<div class="content" ref="scrollable_parcelles" v-else>
							<pane-ancien-mutation
							v-for="mutation in biens_comparables"
							:mutation="mutation"
							class="bien hov"
							:id="'parcelle-' + mutation.propertyId"
							v-bind:key="mutation.propertyId"
							@mouseenter.native="hover_parcelle(mutation)"
							@mouseleave.native="unhover_parcelle(mutation)"
							/>
						</div>
						-->
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import PaneAncienMutation from './PaneAncienMutation.vue'
import SwitchButton from '../global/SwitchButton'
import VueJsonToXls from 'vue-json-excel'
import InputNumber from 'primevue/inputnumber';
import Slider from 'primevue/slider';

export default {
	name: 'PaneAncienMutations',
	data: function() {
		return {
			value4: [20,80],
			detail: '',
			price_min:0,
			price_max:9999999,
			t1:true, 
			t2:true, 
			t3:true, 
			t4:true, 
			t5:true, 
			col: true,
			ind:true,
			area_min: 0,
			area_max: 999,
			cv: false,
			nbmois: 60,
			distance: 200,
			biens_comparables: [],
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {

	}),

	watch: {
		'app.mapdistance': function(newValue) {
			this.distance = newValue
			this.valide_filtre()
		},		
		// eslint-disable-next-line
		'app.mapdistancetime': function(newValue) {
			this.valide_filtre()
		},		
		// eslint-disable-next-line
		'app.cityscan.adresse.biens_comparables': function(newValue) {
			if (newValue) {
				this.biens_comparables = this.getBiensFilter()
			}
		},

		'app.expanded_parcelle': function(newValue) {
			if (newValue) {
				let x;
				let element;
				let $scroll = $(this.$refs.scrollable_parcelles)
				let $program = $scroll.find('#parcelle-' + newValue)
				if ($program && $program[0]) {
					x = $program[0].offsetTop;
					element = $program[0].offsetParent;
					while (element) {
						x += element.offsetTop
						try {
							x -= 30
							element = element.offsetParent;
						} catch (error) {
							break;
						}
					}
					$scroll.animate({ scrollTop: x }, 250)
				}
			}

		}
		
	},
	methods: {
        selectContent(event) {
          event.target.select();
        },

		sliderChange: function() {
			gtag('event', 'filtre_transactions_annees', {'event_category': 'filtre','event_label': 'filtre_transactions_annees'}); this.$store.dispatch('send_event_backend', {module: 'ancien_transactions', action: 'filtre', option: 'annee'});
		},
		
		getBiensFilter: function() {
			/*
			let t = []
			for (let index = 0; index < this.app.cityscan.adresse.biens_comparables.length; index++) {
				if (this.app.cityscan.adresse.biens_comparables[index].filter) {
					t.push(this.app.cityscan.adresse.biens_comparables[index])
				}
			}
			return t
			*/
			return this.app.cityscan.adresse.biens_comparables
		},

		getNameFile: function() {
			let f = 'efocus_dvf_' + this.app.adr_init.name + ' - ' + this.app.adr_init.postcode + ' - ' + this.app.adr_init.city
			f = f.replace("'", "")
			f = f + '.xls'
			return f
		},

		getJsonAncien: function() {
			let r = []
			if (this.biens_comparables) {
				for (let index = 0; index < this.biens_comparables.length; index++) {
					const mutation = this.biens_comparables[index];
					r.push({type: mutation.propertyType, nbpieces : mutation.rooms, cree: mutation.baseDate,
							surface: mutation.area, prix: mutation.price,
							adresse: mutation.fullAddress
					})
				}
			}		
			return r
		},

		// eslint-disable-next-line
		toggle_switch: function(filter) {
			gtag('event', 'filtre_transactions_typologie', {'event_category': 'filtre','event_label': 'filtre_transactions_typologie'});
			this.$store.dispatch('send_event_backend', {module: 'ancien_transactions', action: 'filtre', option: 'typologie'});
		},
		isNumber: function(evt) {
			evt = (evt) ? evt : window.event;
			let charCode = (evt.which) ? evt.which : evt.keyCode;
			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
				evt.preventDefault();
			} else {
				return true;
			}
		},

		// eslint-disable-next-line
		valideInterval: function(val, event) {
			if (val) {				
				switch (val) {
					case 'price_min':
						gtag('event', 'filtre_transactions_prix', {'event_category': 'filtre','event_label': 'filtre_transactions_prix'});
						this.$store.dispatch('send_event_backend', {module: 'ancien_transactions', action: 'filtre', option: 'prix'});
						break;
					case 'price_max':
						gtag('event', 'filtre_transactions_prix', {'event_category': 'filtre','event_label': 'filtre_transactions_prix'});
						this.$store.dispatch('send_event_backend', {module: 'ancien_transactions', action: 'filtre', option: 'prix'});
						break;
					case 'area_min':
						gtag('event', 'filtre_transactions_surface', {'event_category': 'filtre','event_label': 'filtre_transactions_surface'});
						this.$store.dispatch('send_event_backend', {module: 'ancien_transactions', action: 'filtre', option: 'surface'});
						break;
					case 'area_max':
						gtag('event', 'filtre_transactions_surface', {'event_category': 'filtre','event_label': 'filtre_transactions_surface'});
						this.$store.dispatch('send_event_backend', {module: 'ancien_transactions', action: 'filtre', option: 'surface'});
						break;
				
					default:
						break;
				}				
			}
		},

		valide_filtre: function() {
			let data = {price_min: this.price_min,price_max: this.price_max, t1:this.t1, t2:this.t2, t3:this.t3, t4:this.t4, t5:this.t5, area_min: this.area_min, area_max: this.area_max, nbmois: this.nbmois, distance: this.distance, col: this.col, ind: this.ind}
			this.$store.dispatch('set_filter_ancien', data)
				.then(() => {
					this.biens_comparables = this.getBiensFilter();
				})
		},

		applique_filtre: function() {
			this.valide_filtre()
			this.cv = false
		},

		hover_parcelle: function(mutation) {
			if (mutation.marker) {
				mutation.marker.classList.add('hover')
				mutation.marker.classList.add('hover-from-list')
			}
		},

		unhover_parcelle: function(mutation) {
			if (mutation.marker) {
				mutation.marker.classList.remove('hover')
				mutation.marker.classList.remove('hover-from-list')
			}
		},

		GetClass: function(type) {
			switch (type) {
				case 'Dépendance':
					return 'dependance'
				case 'Maison':
					return 'maison'
				case 'Appartement':
					return 'appartement'
				case 'Terrain':
					return 'terrain'
				case 'Local industriel. commercial ou assimilé':
					return 'local'
				default:
					break;
			}
		},

		getPrice: function(price) {
            const euro = new Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 0
            });
			if (price==0) {
				return "-"
			} else {
                return euro.format(price)
			}
		},
	},

	mounted: function() {
	},

	// eslint-disable-next-line
	components: { InputNumber, PaneAncienMutation, SwitchButton, VueJsonToXls, Slider}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.ff
	height calc(100% - 530px) !important

.text
    font-size 16px
	text-align center
	display block
	line-height 32px
	white-space nowrap
	p
		padding 0 0 8px 0
		&:last-child
			padding 0

.pane-ancien-mutations-enter
	transform translateX(100%)
.pane-ancien-mutations-leave-to
	transform translateX(-100%)

.pan-ancien-mutations
	position absolute
	top headersize
	float left
	transition 0.5s easeOutQuart
._filtre
	top 84px
	height calc(100% - 144px) !important
	width 100%
.infinite-loader
	position absolute
	left 16px
	top calc(50% - 64px)
	.loader
		display inline-block
		background main_color
		border none
		border-radius 100%
		color #fff
		.icon
			display block
			animation rotate 1.5s infinite linear

.right-bar
	height 100% !important

.switch-button
	padding 0 0 8px 4px !important
.box
	background #f0f0f0	
	
.box-content
	display block
	padding 0 16px
.box-title
	padding 0 16px
	margin 0 0 4px 0
	font-family volte, sans-serif
	font-weight 600
	color #333
	line-height 16px
	text-align left
	padding-bottom 8px
	padding-top 8px
._box-title2
	padding 0 8px
	margin 0 0 4px 0
	font-family volte, sans-serif
	color #333
	line-height 16px
	text-align left
	padding-bottom 8px
	padding-top 8px
	font-family "Roboto", arial, sans-serif

.switches
	margin-left -8px
	&:after
		content ''
		display block
		clear both
.value
	display inline-block
	text-align right
	border none
	width 100%
	padding 0 4px 0 4px
	height 32px
	background-color #fff
	border-radius 2px
	font-size 1.1rem
	line-height 32px
	outline none

.biens
	top 60px
	height calc(100% - 60px)
	font-size .95rem

.bien
	padding-left 12px
	padding-top 12px
	//width 334px !important
	width calc(100% + 4px) !important

.cs-transaction-id
	position: absolute
	left: 50px
	top 10px

.cs-transaction-price
	text-align: right
	position: absolute
	right: 20px
	top 10px
	font-weight 600

.cs-transaction-year
	position: absolute
	left: 150px
	top 10px

.cs-transaction-type
	position: absolute
	left: 200px
	top 10px

.cs-transaction-parcel
	top 34px
	left: 50px

.cs-collection-card-header
	border-bottom 1px solid #EFEFEF
	height 60px

.cs-widget-collection-card .cs-collection-data-tag
	border-radius 20px
	font-size .813rem
	padding 7px 10px
	color #fff
	display inline-block

.cs-collection-card-content
	padding-top 10px
	padding-bottom 10px
	border-bottom 1px solid #EFEFEF

.cs-collection-data-row
	padding-top 10px

.cs-collection-detail-link
	left 220px
	width 100%
	padding 16px 0

.maison
	background-color rgb(64, 137, 0)
.appartement
	background-color rgb(6, 129, 175)
.terrain
	background-color rgb(255, 167, 38)
.dependance
	background-color rgb(255, 23, 19)
.local
	background-color rgb(102, 0, 229)

.titrefiltre
	width 60px;
	height 60px;
	position absolute
	right 60px
	border-left 1px solid #ccc
	text-align center
	line-height 60px
	cursor pointer
	&.idf
		right 0px !important
.filtreancien
	width 60px;
	height 60px;
	position absolute
	right 60px
	border-left 1px solid #ccc
	text-align center
	line-height 60px
	cursor pointer

.programs-list
	height 100%
	.programs-list-content
		border-right 1px solid #ccc

.export
	width 60px;
	position absolute
	right 0px
	border-left 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer

.head_filter
	width 60px;
	height 60px;
	position absolute
	left 0px
	text-align center
	line-height 60px
	cursor pointer
	&.idf
		right 0px !important

.filters
	cursor pointer
	transition 0.25s easeOutQuart
	font-weight 400
	color #666
	&:hover
		color #6C21F9

.module_ret
	width PaneLeftSize
	height 60px
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid palette_gris_bordure
	border-right 1px solid palette_gris_bordure



@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
